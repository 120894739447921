import React from 'react';
import { useContext, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import Paginator from "../Common/Paginator/Paginator";
import HomeFilter, { InputFilterI } from "../Layout/Home/HomeFilter";
import HomeLayout from "../Layout/Home/HomeLayout";
import { LangContext } from "../../Contexts/Lang";
import DomainsContext from "../../Contexts/Domains/DomainsContext";
import HomeToolkit from "../Layout/Home/HomeToolkit";
import DomainsTable from "./DomainsTable";
import ProcessDomain, { EditDomainI } from "./ProcessDomain/ProcessDomain";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '../Common/Tooltip/Tooltip';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import BulkLoadModal from './BulkLoadModal/BulkLoadModal';
interface DomainsI {
	history: any,
}
export default function Domains(props: DomainsI) {
	const { dispatch: { translate } } = useContext(LangContext);
	const { domains, filters_domains, dispatch: { getDomains, setFiltersDomains, cleanFiltersDomains } } = useContext(DomainsContext);
	const [procesDomain, setprocesDomain] = useState<{ show: boolean, data: undefined | EditDomainI }>({ show: false, data: undefined })
	const handleCloseprocesDomain = () => { setprocesDomain({ show: false, data: undefined }) }
	const handleShowprocesDomain = (data?: EditDomainI) => { setprocesDomain({ show: true, data: data }) }
	const [batchLoadModal, setbatchLoadModal] = useState<{ show: boolean }>({ show: false })
	const handleCloseBatchLoadModal = () => { setbatchLoadModal({ show: false }) }
	const handleShowBatchLoadModal = () => { setbatchLoadModal({ show: true }) }
	const handleOpenBulkLoadModal = () => setbatchLoadModal({ show: true })

	const paginator = Paginator({
		reloadCall: () => { },
		onChangePage: (i: any) => {
			handleGetDomains(i + 1);
			paginator.setActualPage(i)
		},
		onItemsPerPageChange: (i: any) => {
			handleGetDomains(1, i);
			paginator.setActualPage(i)
		}
	})

	function handleGetDomains(page?: number, pageSize?: number, filtersClean?: boolean) {
		getDomains(
			filtersClean ? undefined : filters_domains,
			page ?? paginator.actualPage + 1,
			pageSize ?? paginator.itemsPerPage
		).then(() => {
			if (page !== undefined) paginator.setActualPage(page - 1)
		})
	}

	useEffect(() => {
		let isMounted = true
		if (isMounted && (!domains.loaded && !domains.loading)) handleGetDomains()
		if (isMounted && domains.loaded) {
			paginator.pageSize_set(domains.data?.rowsCount ?? 0)
			paginator.setActualPage((domains.data?.pageNumber ?? 1) - 1)
		}
		if (isMounted && domains.refresh) handleGetDomains(1, paginator.itemsPerPage, true)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [domains])

	function handleSortByFilter(value: string) {
		if (value !== filters_domains.sortColumn) {
			setFiltersDomains("sortColumn", value)
			handleGetDomains(1);
		}
	}

	const inputFilterData: InputFilterI[] = [
		{   //domainidSearch,domainnameSearch,studynameSearch,usernameSearch
			type: "number",
			stateName: "domainidSearch",
			placeholder: `${translate('domain.domainId')}...`,
		},
		{
			type: "text",
			stateName: "domainnameSearch",
			placeholder: translate('domain.domainName'),
		},
		{
			type: "text",
			stateName: "studynameSearch",
			placeholder: translate('home.filter.studyNameCodeOrBatch'),
		},
		{
			type: "text",
			stateName: "usernameSearch",
			placeholder: translate('common.username'),
		},
	]

	const buttonsecondElementLeftToRight = <div style={{ display: "flex", alignItems: "center", height: 32 }}>
		<Button onClick={() => handleOpenBulkLoadModal()}
			aria-controls="example-fade-text"
			variant='outline-primary'
			style={{
				borderRadius: "50px",
				padding: "0 8px",
				border: "none",
				margin: "2px 4px 1px",
				height: "29px",
				width: "29px",
				alignItems: "center",
				display: "flex",
				justifyContent: "center",
			}}
		>
			<Tooltip label={"upload by batch"}>
				<FontAwesomeIcon icon={faFileUpload}
					style={{ border: "none", fontSize: "17px" }}
					data-tip="Add case"
				/>
			</Tooltip>
		</Button>
	</div>
	return (
		<>
			<BulkLoadModal history={props.history}
				show={batchLoadModal.show} 
				handleCloseBulkLoadModal={() => handleCloseBatchLoadModal()} 
				handleOpenBulkLoadModal={() =>  handleShowBatchLoadModal()} />
			<ProcessDomain history={props.history}
				show={procesDomain.show}
				editDomain={procesDomain.data}
				handleClose={handleCloseprocesDomain}
				handleReload={() => { handleGetDomains(1) }}
			/>
			<HomeLayout history={props.history}
				filterTitle={translate('common.domains')}
				rowsAmount={{
					loading: !domains.loaded || domains.loading,
					amount: domains?.data?.rowsCount ?? 0,
				}}
				Filters={
					<div style={{ width: "100%" }}>
						<HomeFilter filters={filters_domains}
							setFilter={setFiltersDomains}
							handleCleanFilter={() => {
								cleanFiltersDomains();
								handleGetDomains(1, paginator.itemsPerPage, true)
							}}
							callFilter={() => {
								handleGetDomains(1)
							}}
							inputFilters={inputFilterData}
						// setLaunchFilter={handleLaunchFilter}
						/>
					</div>
				}
				Toolkit={
					<HomeToolkit rowData={(domains.data?.results ?? [])}
						// rowDataSelected={selectedsDomains}
						// handleSelectAll={handleSelectAllDomains}
						addElement={{
							tooltipLabel: translate('domain.addDomain'),
							handleCreate: handleShowprocesDomain,
						}}
						secondElementLeftToRight={buttonsecondElementLeftToRight}
						sortBy={{
							value: filters_domains.sortColumn ?? "",
							options: "default",
							handleSortBy: handleSortByFilter
						}}
					/>
				}
				FiltersEmpty={
					<div style={{ width: "100%" }}>
						<HomeFilter inputFilters={inputFilterData} />
					</div>
				}
				Table={
					<Card style={{ borderRadius: "0px" }}>
						<Card.Body style={{ padding: "0px" }}>
							{(!domains.loading && (domains?.data?.results ?? []).length === 0) ?
								<div>
									<p style={{ color: "gray", margin: "30px", textAlign: "center", fontFamily: "'Raleway', sans-serif" }}>
										{translate('home.noCasesFound')}
									</p>
								</div> :
								<DomainsTable history={props.history}
									handleReload={() => { handleGetDomains(1) }}
									handleShowprocesDomain={handleShowprocesDomain}
								// domainsSelecteds={selectedsDomains}
								// handleDomainsSelecteds={handleSelectedsDomains}
								/>
							}
						</Card.Body>
						{(domains?.data?.results ?? []).length > 0 ?
							<Card.Footer>
								{paginator.component}
							</Card.Footer>
							: <></>}
					</Card>
				}
			/>
		</>
	)
}
