
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import LabelPopover from '../LabelPopover';
import BadgeOncogenicEffect from '../Badges/BadgeOncogenicEffect';
import { EvidenceIconPopup, evidenceLevels } from './EvidenceLevels';
import { Tooltip } from '../Tooltip/Tooltip';
import { getOncoKbLinkFromInfoCD } from './DbLinks';
interface OncokbDetailI {
	infoCd: any,
}

export default function OncokbDetail(props: OncokbDetailI) {
	const infoCd = props.infoCd
	const { theme } = useContext(ThemeContext);
	const { dispatch: { translate } } = useContext(LangContext);
	const thereIsOncokbMutation = infoCd.ONCOKB_ALTERATION && infoCd.ONCOKB_ALTERATION.trim().length > 2;
	if (!thereIsOncokbMutation) return <></>
	const getLevelOfEvidence = () => {
		let act: any[] = infoCd.ONCOKB_ACCIONABLE.split("[]")
			.filter((x: string) => x.trim().length !== 0)
			.map((x: string) => {
				const evidenceSplitted = x.split(":")
				return {
					level: evidenceSplitted[0].trim(),
					cancer: evidenceSplitted.length > 1 ? evidenceSplitted[1].trim().replace("_", " ") : "-",
					drug: evidenceSplitted.length > 2 ? evidenceSplitted[2].trim() : "-"
				}
			})
			.filter((x: any) => evidenceLevels.includes(x.level.trim()))
			.map((x: { level: string, cancer: string, drug: string }, i: number) => {
				return <div key={i}><Row style={{ margin: 3, display: "inline" }}>
					<span style={{ display: "inline-flex" }}>
						<EvidenceIconPopup level={x.level} />&nbsp;
					</span>
					&nbsp;-&nbsp;
					<Tooltip label={translate("common.cancerTypes")}>
						<span style={{ fontWeight: "bold", color: theme.grayHeader }}>
							{x.cancer.replaceAll("_", " ")}
						</span>
					</Tooltip>
					&nbsp;|&nbsp;
					<Tooltip label={translate("common.drugs")}>
						<span>
							{x.drug.replaceAll("_", " ").replaceAll("|", ", ")}
						</span>
					</Tooltip>
				</Row></div>
			}
			)
		return <>
			<hr style={{ margin: "7px 0" }} />
			<Row style={{ margin: 0, minInlineSize: "max-content" }}><strong>Evidence Level</strong> :</Row>
			<div style={{ overflow: "auto", maxHeight: 100, }}>
				{act}
			</div>
			<hr style={{ margin: "7px 0" }} />

		</>
	}
	const getDescriptionComponent = () => {

		const oncokbDescription: string = infoCd.ONCOKB_DESCRIPTION.replaceAll("_", " ").replaceAll("-", " ")
		if (oncokbDescription.length < 50) return oncokbDescription;
		let descriptionSlice = oncokbDescription.length > 50 ? oncokbDescription.slice(0, 50) + "..." : oncokbDescription;
		return (
			<LabelPopover popoverTitle={
				<span style={{ margin: 0, padding: 0 }}>
					{translate("common.description")}
				</span>}
				trigger={'hover'}
				popoverBody={oncokbDescription}
				label={descriptionSlice} />
		)
	}

	const oncokbLink: any = thereIsOncokbMutation ?
		getOncoKbLinkFromInfoCD(infoCd, infoCd?.GENE_NAME) :
		getOncoKbLinkFromInfoCD(infoCd, infoCd?.GENE_NAME, undefined, "gene");

	return <>
		<Row style={{ margin: 0, display: "flex" }}>
			{
				infoCd.ONCOKB_ONCOGENIC_TAG && infoCd.ONCOKB_ONCOGENIC_TAG.trim().length > 2 ?
					(<Col style={{ margin: 0, padding: 0, marginTop: 2, display: "inline" }}>
						<strong>Oncogenicity
						</strong> :
						<BadgeOncogenicEffect effect={infoCd.ONCOKB_ONCOGENIC_TAG.trim().replaceAll("-", "_")} />
						<span style={{ float: "right", marginRight: 10 }}>
							{oncokbLink}
						</span>
						<br />
					</Col>) : ""
			}
		</Row>
		{
			infoCd.ONCOKB_MUTATION_EFFECT && infoCd.ONCOKB_MUTATION_EFFECT.trim().length > 2 ?
				(<Row style={{ margin: 0, display: "inline" }}>
					<strong>Mutation Effect</strong> : {infoCd.ONCOKB_MUTATION_EFFECT.replaceAll("_", " ").replaceAll("-", " ")}
					{infoCd.ONCOKB_MUTATION_EFFECT_PMIDS ? `| \t\tPMIDS: ${infoCd.ONCOKB_MUTATION_EFFECT_PMIDS}` : ""}
					<br />
				</Row>) : ""
		}
		{
			getLevelOfEvidence()
		}
		{
			infoCd.ONCOKB_DESCRIPTION && infoCd.ONCOKB_DESCRIPTION.trim().length > 2 ?
				(<Row style={{ margin: 0, display: "inline" }}>
					<span><strong>Description</strong> : {getDescriptionComponent()}</span>
				</Row>) : ""
		}
	</>

}