import React, { useContext, useState } from 'react';
import { Modal, Tab, Tabs } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import TabLoadFromProcesamiento from './TabLoadFromProcesamiento';
import TabLoadFromCsv from './TabLoadFromCsv';
interface BulkLoadModalI {
    history: any,
    show: boolean,
    handleCloseBulkLoadModal: () => void,
    handleOpenBulkLoadModal: () => void,
}
export default function BulkLoadModal(props: BulkLoadModalI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const [uploadLaunched, setuploadLaunched] = useState<boolean>(false);

    const handleCloseBulkLoadModal = () => {
        props.handleCloseBulkLoadModal();
        setuploadLaunched(false)
    }
    const handleLaunchRequest = () => {
        setuploadLaunched(true)
        setTimeout(() => {
            setuploadLaunched(false)
            handleCloseBulkLoadModal();
            props.history.push(`/cases`);
        }, 2000);
    }
    return (
        <Modal show={props.show}
            dialogClassName="my-modal"
            onHide={() => handleCloseBulkLoadModal()}
            animation={false}>
            <Modal.Header closeButton style={{ backgroundColor: '#444B59', color: "#fff" }}>
                <Modal.Title >{translate("common.batchLoading")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {uploadLaunched ?
                    <span style={{}}>{translate("common.startingToLoadSamplesOnBatchMode")}</span>
                    :
                    <div>
                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                            <Tab eventKey="home" title={translate("domain.loadFromProcesamiento")}>
                                <TabLoadFromProcesamiento handleLaunchRequest={handleLaunchRequest} />
                            </Tab>
                            <Tab eventKey="profile" title={translate("domain.loadFromCsv")}>
                                <TabLoadFromCsv handleLaunchRequest={handleLaunchRequest} />
                            </Tab>
                        </Tabs>
                    </div>
                }
            </Modal.Body>
        </Modal >
    )
}

