import React, { useContext } from 'react';
import { Badge, Col, Row } from 'react-bootstrap';
import { LangContext } from 'src/Contexts/Lang';
import ThemeContext from 'src/Contexts/Theme/ThemeContext';
import { PmkbDbIcon } from './DbLinks';
import { BadgeTier } from '../Badges/BadgeTier';
import { Tooltip } from '../Tooltip/Tooltip';
import LinkTooltip from '../Links/LinkTooltip';
import LabelPopover from '../LabelPopover';

interface PmkbDataI {
	variant: string;
	tumorTypes: string;
	tissueTypes: string;
	tier: number;
	url: string;
}
interface PmkbDetailI {
	infoCd: any,
}

export default function PmkbDetail(props: PmkbDetailI) {
	const infoCd = props.infoCd
	const { dispatch: { translate } } = useContext(LangContext);
	const { theme } = useContext(ThemeContext);

	const getPmkbDetailData = (): JSX.Element[] => {
		const variant = infoCd["PMKB_VARIANT"]?.split(",_") || [];
		const tumorTypes = infoCd["PMKB_TUMOR_TYPES"]?.split(",_") || [];
		const tissueTypes = infoCd["PMKB_TISSUE_TYPES"]?.split(",_") || [];
		const tiers = infoCd["PMKB_TIER"]?.split(",") || [];
		const url = infoCd["PMKB_URL"]?.split(",_") || [];
		const anyMutations = ["deletion", "frameshift", "indel", "missense", "mutation", "nonsense"];
		let data: PmkbDataI[] = variant.map((v: string, i: number) => {
			let tier = parseInt(tiers.length >= i ? tiers[i] : 0)
			if (tier !== 1 && tier !== 2 && tier !== 3) {
				console.error("Invalid tier: " + tier);
				tier = 0
			}
			return {
				variant: v,
				tumorTypes: tumorTypes.length >= i ? tumorTypes[i] : "-",
				tissueTypes: tissueTypes.length >= i ? tissueTypes[i] : "-",
				tier: tier,
				url: url.length >= i ? url[i] : "-",
			};
		});
		let result = data
			.filter((x: PmkbDataI) => x.tier === 1 || x.tier === 2 || x.tier === 3)
			.map((x: PmkbDataI, i: number) => {
				if (x.variant.split("_").length === 1 && anyMutations.includes(x.variant))
					x.variant = `any ${x.variant}`
				return <div key={i}><Row style={{ margin: 3, display: "inline" }}>
					<span style={{ display: "inline-flex" }}>
						<BadgeTier tier={x.tier}
							variantUndefined={x.variant.trim() === "undefined"} />&nbsp;
					</span>
					&nbsp;-&nbsp;
					<Tooltip label={translate("common.variants")}>
						<Badge key={i}
							style={{ fontSize: "11px" }}
							variant="secondary">
							{x.variant === "undefined" ? translate("common.undefined") : x.variant.replaceAll(":"," ")}
						</Badge>
					</Tooltip>
					&nbsp;
					<LinkTooltip tooltipLabel={`${translate("common.goTo")} ${translate("common.variant")} PMKB`}
						href={x.url}
						fontSize='11px'
						label={""}
					/>
					&nbsp;|&nbsp;
					<Tooltip label={translate("common.tissueTypes")}>
						<span>
							<PmkbTypes valuesInput={x.tissueTypes} type="tissue" />
						</span>
					</Tooltip>
					&nbsp;|&nbsp;
					<Tooltip label={translate("common.tumorTypes")}>
						<span>
						<PmkbTypes valuesInput={x.tumorTypes} type={"tumor"} slice={2} />
						</span>
					</Tooltip>
				</Row></div>
			}
			)
		return result
	}
	const pmkbData = getPmkbDetailData()
	return <>
		{infoCd.PMKB_VARIANT && infoCd.PMKB_VARIANT.trim().length > 2 ?
			(<>
				<Row style={{ margin: 0 }}>
					<Col md={11} style={{ margin: 0, padding: 0, marginTop: 2 }}>
						<span style={{ color: theme.grayHeader }}>
							{translate("casesDetail.totalEvidences")}:&nbsp;
						</span>
						{pmkbData.length}
					</Col>
					<Col md={1} style={{ margin: 0, padding: 0, marginTop: 2 }}>
						<span style={{ float: "right", marginRight: 10 }}>
							{PmkbDbIcon({ defaultLink: true })}
						</span>
					</Col>
				</Row>
				<Row style={{ margin: 0, display: "contents" }}>
					<hr style={{ margin: "7px 0" }} />
					<div style={{ overflow: "auto", maxHeight: 100, }}>
						{pmkbData}
					</div>
				</Row>
			</>
			) : ""}

	</>
}

interface PmkbTypesI {
	valuesInput: string
	type: "tissue" | "tumor"
	slice?: number
}
function PmkbTypes(props: PmkbTypesI) {
	const { dispatch: { translate } } = useContext(LangContext);
	let slice = props.slice ?? 3;
	let values = props.valuesInput.replaceAll("|", ",").replaceAll("_", " ").split(",");
	let type = props.type;

	let valuesSlice = values;
	let valuesDescription = [];
	if (values.length > slice) {
		valuesSlice = values.slice(0, slice).concat("...");
		valuesDescription = values;
	}
	const result = valuesSlice.map((x: string, i: number) => {
		if (valuesDescription.length > 0 && i === slice) {
			return (<LabelPopover key={i}
				popoverTitle={
					<span style={{ margin: 0, padding: 0 }}>
						{type === "tissue" ? translate("common.tissueTypes") : translate("common.tumorTypes")}
					</span>}
				trigger={'hover'}
				popoverBody={values.join(", ")}
				label={
					<Badge key={i}
						style={{ fontSize: "11px", cursor: "pointer" }}
						variant="light">
						{x}
					</Badge>
				} />)
		}
		return (<span key={i}>
			{type === "tissue" ?
				<span><Badge key={i}
					style={{ fontSize: "11px" }}
					variant="light">
					{x.replaceAll("_", " ")}
				</Badge>{i !== values.length - 1 ? ", " : ""}</span> :
				<span>
					{x.replaceAll("_", " ")}
					{i !== values.length - 1 ? ", " : ""}
				</span>
			}
		</span>
		)
	})
	return <>{result}</>
}
