import React from 'react';
import LinkTooltip from "./LinkTooltip";
import { LangContext } from "../../../Contexts/Lang";
import { useContext } from "react";

interface LinkCnvI {
    fontSize?: string;
    genomeRef: string,
    chromosome: string,
    start: string,
    end: string,
    label?: string,
}
// http://dgv.tcag.ca/dgv/app/home?ref=GRCh38/hg38
//     Ej: http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg38/?name=chr7%3A71890181-72690180;search=Search
// Data Base of Genomica Variants :  A curated catalogue of human genomic structural variation
function LinkDgv(props: LinkCnvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const genomeRef = props.genomeRef.indexOf("38") > -1 ? 38 : 37;
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} DGV - Data Base of Genomica Variants`}
            href={`http://dgv.tcag.ca/gb2/gbrowse/dgv2_hg${genomeRef}/?name=chr${props.chromosome}%3A${props.start}-${props.end};search=Search`}
            label={props.label ?? "DGV"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

function LinkUcsc(props: LinkCnvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    //UCSC: Universidad de California, Santa Cruz - UCSC Genome Browser on Human 
    // const url = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&highlight=hg38.chr19%3A46755877-46755877&position=chr19%3A46755852-46755902`
    // const url = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&lastVirtModeType=default&virtModeType=default&virtMode=0&position=chr1%3A108192354%2D108192793`
    // const url = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg${genomeRef}&lastVirtModeType=default&virtModeType=default&virtMode=0&position=chr1%3A108192354%2D108192793`
    const genomeRef = props.genomeRef.indexOf("38") > -1 ? 38 : 37;
    const chromosome = props.chromosome.replace("chr","");
    // const url = `https://genome.ucsc.edu/cgi-bin/hgTracks?db=hg${genomeRef}&highlight=hg${genomeRef}.chr${chromosome}%${props.start}-${props.end}&position=chr19%3A46755852-46755902`
    // url to CNV:
    const baseUrl = "https://genome.ucsc.edu/cgi-bin/hgTracks"; 
    let url = `${baseUrl}?db=hg${genomeRef}&position=chr${chromosome}%3A${props.start}%2D${props.end}`
    // url to variant:
    if(!props.end || `${props.end}`.trim().length === 0 ){
        const startInt : number = +props.start; 
        url = `${baseUrl}?db=hg${genomeRef}`
        url = `${url}&highlight=hg${genomeRef}.chr${chromosome}%3A${startInt}-${startInt}`
        url = `${url}&position=chr${chromosome}%3A${startInt-25}-${startInt+25}`
    }
    //url to CNV
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} UCSC - Genome Browser on Human`}
            href={url}
            label={props.label ?? "UCSC"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

function LinkDecipherGenomics(props: LinkCnvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const isGrch37 = props.genomeRef.indexOf("37") > -1;
    const url = `https://www.deciphergenomics.org/search/patients/results?q=${isGrch37 ? "grch37%3A" : ""}${props.chromosome}%3A${props.start}-${props.end}`
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} Decipher Genomics`}
            href={url}
            label={props.label ?? "Decipher"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}
interface LinkGnomadI {
    fontSize?: string;
    chromosome?: string,
    start?: string,
    end?: string,
    genomeRef?: string,
    label?: string,
}
function LinkGnomad(props: LinkGnomadI) {
    const { dispatch: { translate } } = useContext(LangContext);
    let url = `https://gnomad.broadinstitute.org`;
    const tooltipLabel = `${translate("common.goTo")} gnomAD`;
    const genomeRef = (props.genomeRef ?? "").indexOf("37") > -1 ? 37 : 38;
    if (!props.chromosome || !props.start || !props.end)
        return (
            <LinkTooltip tooltipLabel={tooltipLabel}
                href={url}
                label={"gnomAD"}
                fontSize={props.fontSize ?? "auto"}
            />
        )
    url += `/region/${props.chromosome}-${props.start}-${props.end}?dataset=${genomeRef === 37 ? "gnomad_r2_1" : "gnomad_r4"}`
    return (
        <LinkTooltip tooltipLabel={tooltipLabel}
            href={url}
            label={props.label ?? "gnomAD"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

// https://search.clinicalgenome.org/kb/gene-dosage?page=1&size=25&search=
interface LinkClinicalGenomeI {
    geneName: string,
    label?: string,
    fontSize?: string
}
function LinkClinicalGenome(props: LinkClinicalGenomeI) {
    const { dispatch: { translate } } = useContext(LangContext);
    // https://www.deciphergenomics.org/search/patients/results?q=1%3A108192354-108192793
    const url = `https://search.clinicalgenome.org/kb/gene-dosage?page=1&size=25&search=${props.geneName}`
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} Clinical Genome: search by ${translate("common.geneName")} ${props.geneName}`}
            href={url}
            label={props.label ?? "Clinical Genome"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}

interface LinkCnvCalcI {
    gain: boolean,
    fontSize?: string
}
function LinkCnvCalc(props: LinkCnvCalcI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const gain = props.gain;
    return (
        <LinkTooltip tooltipLabel={`${translate("common.goTo")} CNV Pathogenicity Calculator - ClinGen`}
            href={`https://cnvcalc.clinicalgenome.org/redmine/projects/cnvcalc/cnv_calculator/cnv-${gain ? "gain" : "loss"}`}
            label={"ClinGen CNV Calculator"}
            fontSize={props.fontSize ?? "auto"}
        />
    )
}


export { LinkDgv, LinkUcsc, LinkDecipherGenomics, LinkClinicalGenome, LinkCnvCalc, LinkGnomad }
