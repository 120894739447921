import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import { LangContext } from 'src/Contexts/Lang';
import StudyService from 'src/Services/StudyServices';
import BulkLoadTableCsv from './BulkLoadTableCsv';

interface TabLoadFromCsvI {
    handleLaunchRequest: () => void
}
export default function TabLoadFromCsv(props: TabLoadFromCsvI) {
    const { dispatch: { translate } } = useContext(LangContext);
    const StudyS = new StudyService();
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };
    useEffect(() => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content: any = e.target?.result;
                let strContent = content as string;
                setFileContent(strContent);
            };
            reader.readAsText(selectedFile);
        }
    }, [selectedFile]);

    const handleUploadBatchSamples = () => {
        if (selectedFile) {
            // handleShowBatchLoadingModal()
            const formData = new FormData();
            formData.append('file', selectedFile);
            StudyS.upload_batch_cases(formData).then((res: any) => {
                setSelectedFile(null);
                props.handleLaunchRequest();
                if (res.status === 200) {
                    // console.log("loading...")
                }
                // setopen(false)
            })
        } else {
            setSelectedFile(null);
            // console.log('No file selected');
        }
    };
    return (
        <div style={{ padding: "10px 0 0 0" }}>
            <Form id="example-fade-text" style={{ display: "flex", alignItems: "center" }}>
                <Form.File
                    id="custom-file"
                    label={selectedFile?.name ?? translate("common.selectFile")}
                    custom
                    data-browse={".csv"}
                    onChange={handleFileChange}
                />&nbsp;
                {selectedFile?.name &&
                    <Button variant={"outline-success"} onClick={() => handleUploadBatchSamples()}>{translate("common.upload")}</Button>}
            </Form>
            <div>
                {fileContent && (
                    <div>
                        <BulkLoadTableCsv csvData={fileContent} />
                    </div>
                )}
            </div>
        </div>
    )
}


